@import 'utils/styles';

.container {
  position: relative;
  border: none;
  outline: none;
  background: none;
  display: flex;
  align-items: center;
  @include mobileOnly {
    margin: 0 auto 15px;
    order: 2;
  }

  span{
    color: $color-blue;
    margin-left: 10px;
  }
  img{
    width: 24px;
  }

}
