@import 'utils/styles';

.container {
  width: 100%;
  color: $color-black;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;

  h3, h4 {
    font-family: "robotoblack";
    font-size: 1em;
  }

  h3 {
    font-size: 1.4em;
  }

  h4 {
    margin: 30px 0;
  }

  p {
    margin: 50px 0;
    line-height: 1.2em;

    em {
      color: $color-blue;
    }

    span {
      color: $color-darker-grey;
    }
  }
  .file{
span{
  color: $color-red;
  margin-left: 5px;
  font-size: 22px;
  font-family: 'robotolight';
}
  }
}

.dropzone {
  padding: 25px 0;
  margin-bottom: 30px;
  border-radius: 6px;
  border: 2px dashed $color-darker-grey;
  cursor: pointer;

  &.selected {
    border-color: $color-blue;
  }
  img{
    width: 65px;
  }

  p {
    margin: 20px 0 0;
    span {
      font-size: 0.7em;
    }
  }
}

.importantSection {
  position: relative;
  padding: 10px 20px;
  border: 2px solid $color-blue;
  border-radius: 8px;
  margin-bottom: 40px;


  p {
    padding: 0;
    margin: 0;
    line-height: 1.5em;
    font-family: "robotoblack";

    span {
      font-size: 0.9em;
      display: block;
      color: $color-black;
      font-family: "robotoregular";
    }
  }

  &:after {
    font-family: "robotomedium";
    content: "!";
    position: absolute;
    width: 30px;
    height: 30px;
    color: $color-blue;
    font-size: 20px;
    background-color: $color-grey;
    border-radius: 50%;
    border: 2px solid $color-blue;
    align-items: center;
    justify-content: center;
    display: flex;
    left: -17px;
    top: 50%;
    margin-top: -17px;
  }
}

.successContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-family: "robotomedium";
    font-size: 1.4em;
    margin: 30px 0;
  }
}
