@import 'utils/styles';

.title {
  font-family: "robotoblack";
  font-size: 1.4em;
  line-height: 1.5em;
  color: $color-black;
  text-align: center;
}
.changeBtn{
  position: absolute;
  top: 10px;
  right: -40px;
  font-family: "robotolight";
  font-size: 0.8em;
  color: $color-blue;
  text-decoration: none;
  @include mobileOnly() {
    right: 0;
    top: -25px;
  }
}

.list {
  margin-top: 20px;

  li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 8px 0;
    border-bottom: 1px solid $color-darker-grey;
    font-family: "robotolight";

    .bold {
      font-family: "robotomedium";
    }

    &.blue {
      color: $color-blue;
      font-size: 0.8em;
    }

  }

  &.products {
    li {
      font-family: "robotomedium";
    }
  }

}

.productsTitle {
  margin-top: 30px;
}

.nextContainer {
  margin: 30px 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mobileOnly {
    flex-wrap: wrap;
  }

  button {
    padding-left: 20px;
    padding-right: 20px;
  }
}
