@import 'utils/styles';


.title {
  font-family: "robotoblack";
  font-size: 1.4em;
  line-height: 1.5em;
  color: $color-black;
  text-align: center;
  max-width: 40%;
  margin: 0 auto;
}

.nextContainer {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}


.content {
  margin: 30px 0;

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      height: 50px;
      width: 170px;
      border: 1px solid $color-darker-grey;
      border-radius: 0;
      outline: none;
      transition: all 75ms ease;

      &:hover:not(:disabled) {
        background: $color-dark-grey;
      }

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }

      &.active {
        outline: 1px solid $color-blue;
        border-color: $color-blue;
        z-index: 4;
      }
    }

    img {
      max-width: 90px;
      max-height: 35px;
    }

    .group {
      display: flex;
      flex-direction: row;
    }

  }

  .iconContainer{
    display: flex;
    justify-content: center;
    padding: 20px 0 30px;
  }

  p{
    padding: 10px 0;
    text-align: center;
    font-family: "robotomedium";
    font-size:1.1em;
    line-height: 1.4em;
  }

}

