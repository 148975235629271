@import 'utils/styles';

$size: 100px;

.container {
  position: relative;
  width: $size;
  height: $size;
  border-radius: 50%;
  border: 3px solid $color-blue;

  &:after {
    content: "";
    position: absolute;
    top: $size/4;
    left: ($size/2) - 2px;
    width: 3px;
    height: 50px;
    background-color: $color-blue;
    transform: rotate(45deg);
  }
  &:before {
    content: "";
    position: absolute;
    top: $size/4;
    left: ($size/2) - 2px;
    width: 3px;
    height: 50px;
    background-color: $color-blue;
    transform: rotate(-45deg);
  }
}
