@import 'utils/styles';

$size: 16px;


.content {
  margin-bottom: 10px;
  display: block;
  position: relative;
  padding-left: $size+15px;
  cursor: pointer;
  user-select: none;
  font-family: "robotolight";
  line-height: 1.5em;


  &:hover input ~ .checkmark {
    background-color: $color-dark-grey;
  }

  &.required{
    .text p  {
      border: 1px solid $color-red;
      border-radius: 4px;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $color-blue;
    }
  }

  a {
    color: $color-blue;
    text-decoration: none;
  }

  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: $size;
    width: $size;
    background-color: $color-white;
    border: 1px solid $color-darker-grey;
    border-radius: 4px;
  }

  .text{
    p {
      padding-right: 30px;
      text-align: justify;
    }
  }

}

.container{
  position: relative;
  height: 100%;
  min-height: $size+10px;
  max-height: 40px;
  overflow: hidden;
  transition: all 150ms ease-in;
  @include mobileOnly {
    max-height: unset;
  }

  &.collapsed{
    max-height: 500px;
    &:after{
      background: none;
    }
    .collapse{
      &:before {
        transform: rotate(-45deg);
      }
    }
  }
  &.withoutShadow{
    &:after{
      display: none;
      background: none;
    }
    .collapse{
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(244, 244, 244, 1) 0%, rgba(244, 244, 244, 0.2) 100%);
    height: 24px;
  }

  .collapse {
    position: absolute;
    top: -5px;
    right: 0;
    width: 30px;
    height: 30px;
    cursor: pointer;
    &:before{
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      top: 10px;
      left: 10px;
      border-top: 2px solid $color-blue;
      border-right: 2px solid $color-blue;
      transform: rotate(135deg);
      transition: all 200ms ease-in;
    }
  }

}
