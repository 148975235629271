@import 'utils/styles';

.container {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  min-height: 40px;
  height: 100%;

  input {
    color: $color-black;
    font-family: 'robotomedium';
    width: 100%;
    border-radius: 5px;
    border: 1px solid $color-darker-grey;
    box-sizing: border-box;
    padding: 8px 10px;
    height: 100%;
    &:disabled{
      background: $color-white;
    }
  }

  &.errored{
    input {
      border-color: $color-red;
    }
  }
  &.transparent{
    input{
      background: transparent;
    }
  }

  label {
    display: block;
    font-family: "robotolight";
    color: $default-font-color;
    line-height: 1.4em;
    margin-bottom: 4px;
  }
  .error{
    color: $color-red;
    font-family: "robotolight";
    font-size: 10px;
  }

  .nipCheckboxContainer{
    margin-top: 5px;
  }
}

.datePicker{
  position: relative;
  width: 300px;
}
