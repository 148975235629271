@import 'utils/styles';

.title {
  font-family: "robotoblack";
  font-size: 1.4em;
  line-height: 1.5em;
  color: $color-black;
  text-align: center;
}

.typeContainer {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
}

.inputsContainer {
  margin-top: 10px;
}

.checkboxContainer {
  margin-top: 40px;
}

.nextContainer {
  margin: 30px 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mobileOnly {
    flex-direction: column;
  }
}

.separationGroup {
  margin-top: 10px;
}

.inputGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @include mobileOnly {
    align-items: flex-end;
    flex-direction: column;
  }

  > :not(:last-child) {
    margin-right: 10px;
    @include mobileOnly {
      margin-right: 0px;
    }
  }

  &.small {
    > :last-child {
      width: 55%;
      @include mobileOnly {
        width: 100%;
      }
    }
  }

  &.half {
    > :last-child {
      width: 100%;
    }
  }

  &.selectGroup {
    label {
      padding-left: 0;
    }

    &.withC40 {
      .select {
        width: 33%;
        @include mobileOnly {
          width: 100%;
        }
      }
    }

    .select {
      position: relative;
      @include mobileOnly {
        width: 100%;
      }
      width: 50%;

      &.selectFull {
        width: 100%;
      }

      &.errored {

        .error {
          display: block;
          opacity: 1;
        }
      }
    }


    .selectLabel {
      font-family: "robotolight";
      margin-bottom: 5px;
    }

    .error {
      display: none;
      color: $color-red;
      font-family: "robotolight";
      font-size: 10px;
    }

  }
}

.liabilities {
  margin: 10px 0 30px;

  label {
    display: block;
    font-family: "robotolight";
    margin-bottom: 5px;
  }

  ul {
    font-family: "robotolight";
    background: $color-white;
    border-radius: 6px;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 32px;

    &:nth-child(even) {
      background-color: $color-light-grey;
    }

    &:first-child, &:last-child {
      border-bottom: 1px solid $color-darker-grey;
    }

    span {
      width: 33%;
    }

    .dateWrapper {
      width: 33%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .removeRowWrapper {
      position: relative;
      .removeRow {
        color: $color-red;
        font-size: .9em;
        position: absolute;
        top: -6px;
        right: -18px;
        cursor: pointer;
        @include mobileOnly {
            right: 25px;
        }
      }
    }
  }

  .add {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "robotolight";
    cursor: pointer;

    em {
      display: block;
      position: relative;
      width: 16px;
      height: 16px;
      border: 2px solid $color-blue;
      border-radius: 50%;
      cursor: pointer;
      margin: 0 5px;

      &:after {
        content: "";
        position: absolute;
        width: 8px;
        height: 2px;
        background-color: $color-blue;
        top: 7px;
        left: 4px;
      }

      &:before {
        content: "";
        position: absolute;
        width: 2px;
        height: 8px;
        background-color: $color-blue;
        top: 4px;
        left: 7px;
      }
    }
  }

}

.divider {
  font-family: "robotomedium";
  line-height: 1.5em;
  border-bottom: 1px solid $color-darker-grey;
  margin: 10px 0 20px;
}

.option {
  display: flex;
  align-items: center;
  font-family: "robotomedium";

  label {
    flex: 1;
    padding-left: 10px;
  }

}
