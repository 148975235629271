@import 'utils/styles';

.title {
  font-family: "robotoblack";
  font-size: 1.4em;
  line-height: 1.5em;
  color: $color-black;
  text-align: center;
}

.typeContainer{
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  @include mobileOnly {
    flex-direction: column;
  }
}

.inputsContainer{
  margin-top: 10px;
}
.checkboxContainer{
  margin-top: 40px;
}

.nextContainer{
  margin: 30px 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mobileOnly{
    flex-direction: column;
  }
}
