@import 'utils/styles';

.container {
  position: relative;
  background-color: $color-white;
}

.backNav {
  position: absolute;
  top: 90px;
  left: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:after {
    position: relative;
    content: "";
    width: 20px;
    height: 20px;
    border-top: 3px solid $color-blue;
    border-left: 3px solid $color-blue;
    transform: rotate(-45deg);
    margin-left: 10px;
  }

  &:hover {
    &:after {
      opacity: 0.7;
    }
  }

}

.content {
  position: relative;
  max-width: $default-width;
  width: 100%;
  margin: $content-margin auto;

  footer {
    margin-top: 30px;
    padding: 10px 50px 20px;

    .address {
      text-align: center;
      font-size: .9em;
      margin-top: 40px;
      padding: 0 10px;
      a {
        margin-top: 10px;
        color: $color-blue;
        text-decoration: none;
        display: block;
      }
    }

    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-family: "robotolight";
      font-size: 1.1em;
      @include mobileOnly() {
        flex-direction: column;
        li {
          padding: .5em 0;
          text-align: center;
        }
      }

      li {
        margin: 0 10px;

        a {
          font-family: "robotomedium";
          text-decoration: none;
          color: $color-blue;
        }
      }
    }
  }

}

.formWrapper {
  background-color: $color-grey;
  border-radius: $default-radius;

  header {
    height: $header-height;
    padding: 0 $header-padding 0;
    border-top-left-radius: $default-radius;
    border-top-right-radius: $default-radius;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: rgb(2, 167, 233);
    background: linear-gradient(90deg, rgba(43, 61, 142, 1) 0%, rgba(2, 167, 233, 1) 100%);

    h1, h2 {
      margin: 0;
      color: $color-white;
      font-family: "robotoblack";
      font-size: 1.4em;
      line-height: 1.5em;
      @include mobileOnly {
        line-height: 1.2em;
        text-align: right;
      }
    }
  }

}

.logo {
  width: 150px;
  display: block;
}

.childContainer {
  padding: 30px 0;
  max-width: $content-width;
  margin: 0 auto;
  @include mobileOnly() {
      max-width: $content-mobile-width;
  }
}

footer {
  .header {
    font-family: "robotomedium";
    text-decoration: none;
    color: #1380c7;
  }
}