@import 'utils/styles';

.container {
  position: relative;
  display: inline-block;
  border: none;
  padding: 0.5rem 3rem;
  min-height: $button-height;
  margin: 0;
  text-decoration: none;
  background: linear-gradient(90deg, rgba(43, 61, 142, 1) 0%, rgba(2, 167, 233, 1) 100%);
  color: $color-white;
  font-family: 'robotomedium';
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out,
  transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 5px;
  box-sizing: border-box;
  @include mobileOnly {
    width: 100%;
    margin-bottom: 1em;
    order: 2;
  }
  span{
    transition: all 200ms ease-in;
  }

  &:hover{
    background: linear-gradient(90deg, rgb(52, 70, 175) 0%, rgb(21, 179, 233) 100%);
  }

  &.bordered{
    color: $color-blue;
    background: transparent;
    border: 2px solid $color-blue;
  }
  &.loading{
    pointer-events: none;
    cursor: progress;
    background:linear-gradient(90deg, rgba(43, 61, 142, 0.4) 0%, rgba(2, 167, 233, 0.4) 100%);
    span{
      visibility: hidden;
    }
  }
  &.blank{
    color: $color-blue;
    background: none;
    border-color: transparent;
    outline: none;
  }
  &.disabled{
    cursor: not-allowed;
    background: $color-darker-grey;
    color: $default-font-color;
  }
}

.loader{
  position: absolute;
  top: 50%;
  margin-top: -12px;
  border: 3px solid $color-grey;
  border-top: 3px solid $color-blue;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 750ms linear infinite;
  left: 50%;
  margin-left: -12px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
