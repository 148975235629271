@import 'utils/styles';


.title {
  font-family: "robotoblack";
  font-size: 1.4em;
  line-height: 1.5em;
  color: $color-black;
  text-align: center;
  max-width: 40%;
  margin: 0 auto;
}

.nextContainer {
  margin: 50px 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.content {
  margin: 30px 0;

  .agreement {
    height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    iframe {
      background-color: $color-white;
    }
  }
}

.waitingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 40px 0 20px;
    font-family: "robotomedium";
    line-height: 1.5em;
    text-align: center;
  }
}

.agreementGet{
  .title{
    margin: 40px auto 40px;
    font-size: 1.6em;
    text-align: center;
  }
  p{
    margin: 0 auto;
    width: 80%;
    font-family: "robotomedium";
    font-size: 1em;
    line-height: 1.4em;
    text-align: center;
    color: $color-black;
  }
  .nextContainer{
    justify-content: center;
  }
}
