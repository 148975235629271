.main-select__control{
    height: 100px;
    border-radius: 6px !important;
    border-color: #C6C6C6 !important;
}
.main-select__value-container{
    height: 100%;
}
.main-select__single-value{
    width: 100%;
    padding-right: 20px;
    padding-left: 10px;
    cursor: pointer;
    max-width: 100% !important;
}
@media screen and (max-width: 992px) {
    .main-select__single-value {
        padding-left: 0px;
}
}
.main-select__option.main-select__option--is-selected{
    background: #1380C7;
    color: #fff;
}

.main-select__option.main-select__option:hover{
    background: #438fc7;
    color: #fff;
}
.main-select__indicator-separator{
    display: none;
}
.main-select__indicator.main-select__dropdown-indicator{
    color: #1380C7;
}

.sub-select__control{
    border-radius: 6px !important;
    border-color: #C6C6C6 !important;
    margin-bottom: 5px;
}

.sub-select__single-value{
    width: 100%;
    padding-right: 9px;
    cursor: pointer;
    max-width: 100% !important;
}
.sub-select__indicator-separator{
    display: none;
}

.sub-select__indicator.sub-select__dropdown-indicator{
    color: #1380C7;
}
.sub-select__indicator.sub-select__clear-indicator{
    position: absolute;
    right: -35px;
}

.sub-select__multi-value{
    width: 100%;
    background-color: #F4F4F4 !important;
}
.sub-select__multi-value__label{
    width:100%;
    height: 30px;
    line-height: 23px;
}
.sub-select__value-container.sub-select__value-container--has-value{
    padding: 10px 10px;
}
