@import 'utils/styles';

.radio {
  display: flex;
  position: relative;
  padding-left: 40px;
  margin-right: 40px;
  cursor: pointer;
  user-select: none;
  height: 26px;
  align-items: center;
  @include mobileOnly {
    margin: 0.3em 0;
  }

  &.disabled{
    cursor: not-allowed;
  }

  &:hover:not(.disabled) input~ .checkmark {
    border-color: $color-blue;

    &:after {
      display: block;
    }
  }


  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:not(disabled):checked ~ .checkmark {
      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid $color-darker-grey;
    background-color: $color-dark-grey;
    transition: all 200ms ease;

    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $color-blue;
    }
  }


}

.label {
  font-family: "robotolight";
  color: $default-font-color;
}

