@import 'utils/styles';


.title {
  font-family: "robotoblack";
  font-size: 1.4em;
  line-height: 1.5em;
  color: $color-black;
  text-align: center;
  max-width: 40%;
  margin: 0 auto;
}

.nextContainer {
  margin: 0 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;


   button{
     margin-bottom: 10px
   }



}


.content {
  margin: 30px 0;

  p {
    font-family: "robotomedium";
    line-height: 1.5em;
    max-width: 87%;
    margin: 10px auto 0;
    text-align: center;
  }

}

.waitingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin: 40px 0 20px;
    font-family: "robotomedium";
    line-height: 1.5em;
    text-align: center;
  }
}

.codeContainer {
  position: relative;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mobileOnly {
    padding: 40px 10px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .error {
    position: absolute;
    top: 20px;
    margin-bottom: 2px;
    color: $color-red;
    font-size: 14px;
    font-family: "robotoregular";
  }

  input {
    width: 80px;
    height: 120px;
    margin: 0 8px;
    border: 1px solid $color-darker-grey;
    border-radius: 5px;
    font-size: 90px;
    text-align: center;
    @include mobileOnly {
      font-size: 50px;
      width: 20%;
      height:60px;
      margin: 0;
    }
  }
}
