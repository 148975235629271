@import 'utils/styles';

.title {
  font-family: "robotoblack";
  font-size: 1.4em;
  line-height: 1.5em;
  color: $color-black;
  text-align: center;
  max-width: 60%;
  margin: 0 auto 20px;
}

.subTitle {
  font-family: "robotoblack";
  font-size: 1.1em;
  line-height: 1.5em;
  color: $color-black;
}

.header {
  margin-top: 10px;
  margin-bottom: 5px;
  font-family: "robotolight";
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  .valueLabel {
    display: flex;
    flex-direction: column;
    margin-right: 38px;
    align-items: center;

    div {
      font-family: "robotolight";

      div:first-child {
        font-family: "robotomedium";
      }

      div:nth-child(2) {
        font-family: "robotomedium";
        color: $color-blue;
      }
    }
  }

  .subEl {
    position: relative;
    margin-top: 10px;
  }
}

.selectContainer {
  position: relative;

  .quantityAdd {
    position: absolute;
    width: 20px;
    height: 20px;
    right: -53px;
    top: 8px;
    cursor: pointer;
    border: 1px solid $color-blue;
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 2px;
      background-color: $color-blue;
      transform: rotate(90deg);
      top: 9px;
      left: 5px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 2px;
      background-color: $color-blue;
      transform: rotate(-180deg);
      top: 9px;
      left: 5px;
    }
  }

  .quantityUndo {
    position: absolute;
    width: 20px;
    height: 20px;
    right: -80px;
    top: 8px;
    cursor: pointer;
    border: 1px solid $color-red;
    border-radius: 50%;

    &.quantityDisabled {
      border-color: $color-darker-grey;
      cursor: not-allowed;

      &:after {
        background-color: $color-darker-grey;
      }
    }

    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 2px;
      background-color: $color-red;
      transform: rotate(-180deg);
      top: 9px;
      left: 5px;
    }
  }
}

.months {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: 2px solid $color-dark-grey;
  margin-bottom: 40px;

  li {
    width: 20%;
    padding: 14px 30px;
    cursor: pointer;
    text-align: center;
    font-family: "robotomedium";
    font-size: 1.1em;
    color: $default-font-color;
    z-index: 2;
    @include mobileOnly() {
      padding: 14px 0px;
    }
  }

  &.active-1 {
    &:before {
      left: 0;
    }

    li:nth-of-type(1) {
      color: $color-white;
    }
  }

  &.active-3 {
    &:before {
      left: 20%;
    }

    li:nth-of-type(2) {
      color: $color-white;
    }
  }

  &.active-6 {
    &:before {
      left: 40%;
    }

    li:nth-of-type(3) {
      color: $color-white;
    }
  }

  &.active-12 {
    &:before {
      left: 60%;
    }

    li:nth-of-type(4) {
      color: $color-white;
    }
  }

  &.active-24 {
    &:before {
      left: 80%;
    }

    li:nth-of-type(5) {
      color: $color-white;
    }
  }

  &:before {
    content: "";
    position: absolute;
    width: 20%;
    top: 0;
    left: 0;
    height: 100%;
    background: linear-gradient(90deg, rgba(43, 61, 142, 1) 0%, rgba(2, 167, 233, 1) 100%);
    z-index: 1;
    border-radius: 5px;
    transition: all 125ms ease-in;
  }

}

.mainSelect {
  height: 60px;
}

.option {
  display: flex;
  height: 60px;
  align-items: center;
  font-family: "robotomedium";

  img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }

  label {
    flex: 1;
    padding-left: 10px;
    white-space: normal;

    @include mobileOnly() {
      padding-left: 0px;
      padding-right: 5px;
    }

    p {
      font-family: 'robotolight';
      line-height: 1.3em;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      color: $color-blue;

      &:first-child {
        font-family: "robotoblack";
        color: $default-font-color;
        font-size: 1.1em;
      }
    }
  }

}

.subOption {
  position: relative;
  display: flex;
  align-items: center;
  font-family: "robotomedium";

  .image {
    width: 40px;
    height: 30px;
    object-fit: contain;
  }

  label {
    flex: 1;
    padding-left: 10px;
    flex-direction: row;
    @include mobileOnly() {
      padding-left: 0px;
    }
  }

  .quantity {
    position: absolute;
    right: 110px;
    font-family: "robotolight";
  }

  .prices {
    margin-right: 15px;
    text-align: center;

    span {
      display: block;
      text-align: center;

      &:first-child {
        &:after {
          content: "  ";
        }
      }

      &:nth-child(2) {
        color: $color-blue;
      }

    }
  }
}

.totalValue {
  margin: 30px 0 30px;
  border-radius: 5px;
  border: 2px solid $color-blue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 2.9em 15px 20px;
  font-family: "robotomedium";
  font-size: 1.1em;

  .valueTitle {
    @include mobileOnly {
      max-width: 70%;
    }

    span {
      color: $color-blue;
    }
  }

  .values {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $color-blue;

    div {
      &:first-child {
        font-size: 1.1em;
        font-family: "robotoblack";
        color: $default-font-color;
      }
    }
  }
}

.list {
  margin-top: 15px;

  li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 8px 0;
    border-bottom: 1px solid $color-darker-grey;
    font-family: "robotolight";
    transition: all 125ms ease-in-out;

    @include mobileOnly {
      span:nth-child(1) {
        max-width: 80%
      }
    }

    span:nth-child(2) {
      font-family: "robotomedium";
      text-align: right;
    }

    &.subItem {
      height: 100%;
      max-height: 0;
      overflow: hidden;
      margin: 0;
      padding: 0 0 0 10px;
      border: none;
    }

    .expandArrow {
      position: absolute;
      top: 4px;
      right: -24px;
      cursor: pointer;

      .arrow {
        fill: $color-blue;
        transition: all 175ms ease-out;
      }
    }
  }

  &.active {

    li {
      &.subItem {
        max-height: 40px;
        padding: 8px 0 8px 10px;
        border-bottom: 1px solid $color-darker-grey;
      }

      .expandArrow {
        .arrow {
          transform: rotate(180deg);
        }
      }
    }
  }


}

.pointer {
  cursor: pointer;
}

.emailSection {
  text-align: center;
  margin: 40px 0 20px;

  p {
    font-family: "robotolight";
    line-height: 1.5em;
    max-width: 87%;
    margin: 10px auto 0;
  }
}

.nextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include mobileOnly {
    flex-direction: column;
  }

  .inputContainer {
    width: 45%;
    margin-right: 10px;
    height: 40px;
    position: relative;
    @include mobileOnly {
     width: 90%;
      margin-bottom: 1.8em;
      align-self: center;
    }

    .error {
      font-size: 0.8em;
      color: $color-red;
      position: absolute;
      top: -14px;
      left: 0;
    }
  }

  button {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.responseContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  p {
    margin: 50px 0;

    a {
      text-decoration: none;
      color: $color-blue;
    }
  }
}
