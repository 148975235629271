@import 'utils/styles';

.container{
  padding: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &[data-size=small] {
    padding: 0px;
    .loader {
      width: 30px;
      height: 30px;
    }
  }
}

.loader {
    border: 5px solid $color-dark-grey;
    border-top: 5px solid $color-blue;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 750ms linear infinite;

}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
