$color-grey: #F4F4F4;
$color-light-grey: #f9f9f9;
$color-white: #ffffff;
$color-black: #000000;
$color-dark-grey: #EAEAEA;
$color-darker-grey: #C6C6C6;
$color-blue: #1380C7;
$color-red: #c74134;



$default-font-color: #656565;
$default-width: 880px;
$content-margin: 40px;
$default-radius: 20px;
$content-width: 75%;
$content-mobile-width: 90%;

$header-height: 70px;
$header-padding: 20px;

$button-height: 40px;

$breakpoint: 992px;
@mixin mobileOnly() {
  @media screen and (max-width: $breakpoint)
  {
    @content
  }
}
