@import 'utils/styles';

$size: 100px;

.container {
  position: relative;
  width: $size;
  height: $size;
  border-radius: 50%;
  border: 3px solid $color-blue;
  &:after{
    content: "";
    position: absolute;
    top: $size/4;
    left: ($size/2) - 12px;
    width: 20px;
    height: 40px;
    border-bottom: 3px solid $color-blue;
    border-right: 3px solid $color-blue;
    transform: rotate(45deg);
  }
}
