@import 'utils/styles';


.title {
  font-family: "robotoblack";
  font-size: 1.4em;
  line-height: 1.5em;
  color: $color-black;
  margin: 0 auto;
}

.nextContainer {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}


.content {
  margin: 30px 0;

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      height: 50px;
      width: 170px;
      border: 1px solid $color-darker-grey;
      border-radius: 0;
      outline: none;
      transition: all 75ms ease;

      &:hover:not(:disabled) {
        background: $color-dark-grey;
      }

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }

      &.active {
        outline: 1px solid $color-blue;
        border-color: $color-blue;
        z-index: 4;
      }
    }

    img {
      max-width: 90px;
      max-height: 35px;
    }

    .group {
      display: flex;
      flex-direction: row;
    }
  }
  .datePicker{
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    min-height: 40px;
    height: 100%;


    label {
      display: block;
      font-family: "robotolight";
      color: $default-font-color;
      line-height: 1.4em;
      margin-bottom: 4px;
    }

  }
  .iconContainer{
    display: flex;
    justify-content: center;
    padding: 20px 0 30px;
  }

}

.inputGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @include mobileOnly {
    flex-direction: column;
  }

  >:not(:last-child) {
    margin-right: 10px;
    @include mobileOnly {
      margin-right: 0px;
    }
  }

  &.small {
    > :last-child {
      width: 55%;
    }
  }
}

.summary {
  margin: 30px 0;
}

.list {
  margin: 10px 0 20px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 8px 0;
    border-bottom: 1px solid $color-darker-grey;
    font-family: "robotolight";

    .bold {
      font-family: "robotomedium";
    }

    &.blue {
      color: $color-blue;
      font-size: 0.8em;
    }

  }

  &.products {
    li {
      font-family: "robotomedium";
    }
  }

}

.header{
  text-align: center;
  margin-bottom: 20px;
  color: $color-black;
  h2{
    font-family: "robotoblack";
    font-size: 1.8em;
    line-height: 1.2em;
  }
}

.data{
  display: flex;
  flex-direction: row;
}

.dataElement{

  flex: 1;
  &:first-child{
    margin-right: 5px;

  }
  &:nth-child(2){
    margin-left: 5px;
  }

  p{
    margin: 15px 0 20px;
    border-radius: 4px;
    border: 1px solid $color-darker-grey;
    padding: 15px 20px;
    line-height: 1.6em;
  }
}
