@import 'utils/styles';


.title {
  font-family: "robotoblack";
  font-size: 1.4em;
  line-height: 1.5em;
  color: $color-black;
  text-align: center;
  max-width: 40%;
  margin: 0 auto;
}

.nextContainer{
  margin: 30px 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.onlyNext{
  justify-content: center;
}

.content{
  margin: 30px 0;
}

.waitingContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p{
    margin: 40px 0 20px;
    font-family: "robotomedium";
    line-height: 1.5em;
    text-align: center;
  }
}
