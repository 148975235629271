@import 'utils/styles';

.container {
  background-color: $color-grey;
  border-top-left-radius: $default-radius;
  border-bottom-left-radius: $default-radius;
  padding: 50px 0px 20px 20px;
  position: fixed;
  top: 40%;
  right: 0;
  width: 180px;
  //width: 180px;
  transition: all 200ms ease-in;
  box-shadow: 0px 4px 14px -4px rgba(0,0,0,0.4);

  //&:hover {
  //  width: 180px;
  //
  //  .name {
  //    opacity: 1;
  //  }
  //}

  li {
    position: relative;
    height: 50px;
    background-color: $color-grey;

    a {
      color: $default-font-color;
    }

    .name {
      position: absolute;
      font-family: 'robotolight';
      opacity: 1;
      transition: all 600ms ease-in;
      line-height: 1.2em;

      span {
        font-family: 'robotomedium';
      }

      div {
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .numContainer {
      width: 50px;
      height: 100%;
      background-color: $color-grey;
      position: absolute;
      right: 0;
    }

    em {
      position: absolute;
      top: 0;
      right: 14px;
      color: $color-dark-grey;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 2px solid $color-dark-grey;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 200ms ease-in;
      font-family: "robotolight";
      background-color: $color-grey;
      z-index: 4;
    }

    &.done {
      em {
        color: $color-white;
        background-color: $color-blue;
        font-size: 0.9em;
      }
    }

    &.active {
      em {
        transform: scale(1.1);
        border-color: $color-blue;
        color: $color-blue;
        font-family: "robotomedium";
      }
    }

    &:not(:last-child) {
      .numContainer {
        &:after {
          content: "";
          position: absolute;
          height: 100%;
          width: 2px;
          background-color: $color-dark-grey;
          right: 24px;
          z-index: 2;
        }
      }
    }

  }
}
@media all and (max-width: $breakpoint) {
  .container {
    display: none;
  }
}
@media all and (min-width: $breakpoint) and (max-width: 1300px) {
  .container {
    width: 30px;
    .name {
      opacity: 0;
    }

  }
}
