.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input,
.react-datepicker__tab-loop{
    display: block !important;
    width: 100%;
}

.react-datepicker__triangle{
    left: 82px !important;
}
.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text{
    padding: 7px 4px;
}
.react-datepicker__header{
    padding: 8px;
}

.react-datepicker span.react-datepicker__year-read-view--down-arrow {
    width: auto;
    top: 2px;
    left: 0px;
}
.react-datepicker__navigation {
    width: 0!important;
}